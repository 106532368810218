<template>
  <div
    class="border p-3 mb-4"
    :class="`border-${variant}`"
  >
    <h6 :class="`text-${variant}`">
      {{ title }}
    </h6>
    <p class="mb-0 font-size-sm">
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InfoBox extends Vue {
  @Prop(String)
  title!: string;

  @Prop({ type: String, default: 'info' })
  variant!: string;
}
</script>
