import * as moment from 'moment-timezone';

export class Timetable {
  timetable!: GQL.TimetableInfoFragment;
  city!: Partial<GQL.City>;

  constructor (timetable: GQL.TimetableInfoFragment, city: Partial<GQL.City>) {
    this.timetable = timetable;
    this.city = city;
  }

  get campType () {
    return this.timetable.campType.title;
  }

  /**
   * Format date like "5 january"
   * @param date Date to format
   */
  static formatDate (date: string) {
    return moment(date).format('D MMMM');
  }

  /**
   * Seat description, with city details
   */
  get extendedDescription () {
    return `${this.toString()} i ${this.city.name}`;
  }

  /**
   * Seat description
   */
  toString () {
    const { sport, week } = this.timetable;
    const dateRange = `${Timetable.formatDate(week.startDate)} - ${Timetable.formatDate(week.endDate)}`;
    return `${sport.title}, vecka ${week.number} (${dateRange})`;
  }
}
