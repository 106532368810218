import { Component, Vue } from 'vue-property-decorator';
import { VueApolloQueryDefinition } from 'vue-apollo/types/options';

import cityQuery from '@/graphql/queries/city.graphql';

@Component
export class LoadGQLCityMixin extends Vue {
  activeCity!: GQL.City;

  created () {
    const query: VueApolloQueryDefinition<GQL.CityQuery> = {
      query: cityQuery,
      update: data => data.city,
      variables: {
        cityId: window.activeCityId,
      },
      fetchPolicy: 'cache-first',
    };
    this.$apollo.addSmartQuery('activeCity', query);
  }
}
