import { Component, Vue, Inject } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { Timetable } from '@/store/models/orderables/registration/Timetable.model';
import { Registration } from '@/store/models/orderables/registration/Registration.model';
import { Cart } from '@/store/modules/Cart';
import { Site } from '@/store/modules/Site';
import { CartItem } from '@/store/models/CartItem.model';

@Component
export default class SportsWeekStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  cartItem!: CartItem<Registration>;

  /**
   * Currently selected seat reservation
   */
  selectedTimetable: Timetable | null = null;

  /**
   * Inject from FormWizard
   */
  @Inject()
  editMode!: boolean;

  loading = true;
  showList = false;

  get registration () {
    return this.cartItem.orderable;
  }

  get currentCity () {
    return getModule(Site).cityById(this.registration.cityId)!;
  }

  // Toggle seat list visibility
  get showTimetablesList () {
    return this.showList || !this.editMode || !this.oldTimetableIsRelevant;
  }

  /**
   * Check if timetable should be invalidated
   */
  get oldTimetableIsRelevant () {
    const isRelevant = getModule(Cart).articles
      .some(a => (
        a.orderable instanceof Registration &&
        a.orderable.id === this.registration.id &&
        // If changed gender, old seat is probably not so relevant
        a.orderable.gender === this.registration.gender
      ));

    if (!isRelevant)
      this.selectedTimetable = null;

    return isRelevant && this.selectedTimetable;
  }

  onEnter () {
    this.selectedTimetable = this.registration.timetable;
    this.loading = true;
  }

  onTimetableSelected () {
    if (!this.selectedTimetable)
      return;

    this.loading = true;
    this.$emit('seat-selected', this.selectedTimetable);
  }
}
