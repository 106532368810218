import { Controller } from '@hotwired/stimulus';
import '@ckeditor/ckeditor5-build-classic';

export default class extends Controller {
  editor = null;
  /** @type {HTMLFormElement | null} */
  form = null

  connect () {
    if (this.editor == null)
      // eslint-disable-next-line no-undef
      this.editor = ClassicEditor
        .create(this.element, {
          toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'alignment'],
          heading: {
            options: [
              { model: 'paragraph', title: 'Brödtext' },
              { model: 'heading3', view: 'h3', title: 'Rubrik 1', class: 'ck-heading_heading2' },
              { model: 'heading4', view: 'h4', title: 'Rubrik 2', class: 'ck-heading_heading3' },
              { model: 'heading5', view: 'h5', title: 'Rubrik 3', class: 'ck-heading_heading4' },
            ],
          },
          mediaEmbed: {
            previewsInData: true,
          },
        })
        .then((editor) => {

        })
        .catch((error) => {
          console.error(error);
        });

    this.form = this.findNearestParent(this.element, el => el.tagName === 'FORM');
    this.fixNbsp();
  }

  disconnect () {
    if (this.form)
      this.form.removeEventListener('submit', this.fixNbsprBeforeSubmit.bind(this));
  }

  fixNbsp () {
    if (this.form)
      return this.form.addEventListener('submit', this.fixNbsprBeforeSubmit.bind(this));
  }

  fixNbsprBeforeSubmit (/** @type {SubmitEvent} */ event) {
    const textWithoutNbsp = this.element.value.replaceAll('&nbsp;', ' ');
    this.element.value = textWithoutNbsp;
  }

  /** @return {HTMLElement | null} */
  findNearestParent (/** @type {HTMLElement} */ el, /** @type {(el: HTMLElement) => boolean} */ condition) {
    if (condition(el) || el === null)
      return el;

    return this.findNearestParent(el.parentElement, condition);
  }
}
