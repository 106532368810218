<template>
  <component
    :is="to && !disabled ? 'router-link' : 'div'"
    :to="disabled ? undefined : to"
    class="card border-dashed c-dashed-button"
    :class="{
      ...variantClasses,
      'c-dashed-button--disabled': disabled,
    }"
    @click="$emit('click')"
  >
    <div class="d-flex p-3 justify-content-center align-items-center">
      <i
        v-if="icon"
        :class="`${iconStyle} mr-3 fa-${icon} text-primary`"
      />
      <span>
        <slot />
      </span>
    </div>
  </component>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import { VariantMixin } from '@/mixins/ClassHelperMixins';

@Component({
  mixins: [
    VariantMixin('c-dashed-button'),
  ],
})
export default class DashedButton extends Vue {
  @Prop(String)
  icon!: string;

  @Prop({ type: String, default: 'fas' })
  iconStyle!: string;

  @Prop()
  to;

  @Prop({ type: Boolean, default: false })
  disabled;
}
</script>
