import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="navigation"
export default class extends Controller {
  static targets = ['main', 'anchor', 'content']
  breakpoint = 992;

  connect () {
    // this.moveNavigation();
    // document.addEventListener('resize', function () {
    //   (!window.requestAnimationFrame) ? setTimeout(this.moveNavigation, 300) : window.requestAnimationFrame(this.moveNavigation);
    // });
  }

  checkWindowWidth () {
    // check window width (scrollbar included)
    let e = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    if (e[a + 'Width'] >= this.breakpoint)
      return true;
    else
      return false;
  }

  moveNavigation () {
    const desktop = this.checkWindowWidth();
    if (desktop) {
      const temp = this.mainTarget.parentNode.removeChild(this.mainTarget);
      this.insertAfter(temp, this.anchorTarget);
    } else {
      const temp = this.mainTarget.parentNode.removeChild(this.mainTarget);
      this.element.insertBefore(temp, this.pageTarget);
    }
  }

  insertAfter (newElement, referenceElement) {
    referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
  }
}
