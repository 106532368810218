<template>
  <form-wizard-step
    title="Kontaktuppgifter"
    :validate="validate"
    :before-leave="onBeforeLeave"
    @error="onValidationError"
    @leave="onLeave"
  >
    <form
      ref="form"
      :class="{ 'was-validated': error }"
    >
      <custom-input
        id="streetAddress"
        v-model="registration.details.address.street"
        label="Gatuadress"
        autocomplete="address-line1"
        required
        autofocus
      />
      <custom-input
        id="postalCode"
        v-model="registration.details.address.zipcode"
        v-mask="'### ##'"
        label="Postnummer"
        autocomplete="postal-code"
        required
      />
      <custom-input
        id="city"
        v-model="registration.details.address.city"
        label="Stad"
        autocomplete="address-level2"
        required
      />
      <country-select
        id="country"
        v-model="registration.details.address.country"
        label="Land"
        placeholder="Välj ett land"
        required
      />
      <custom-input
        id="mobilePhoneNumber"
        v-model="registration.details.phone"
        label="Mobilnummer till deltagare"
        autocomplete="tel"
        required
      />
      <checkbox-input
        v-model="registration.details.previousEnrollment"
        variant="primary"
        lg
        block
      >
        Deltagaren har deltagit förut på Stadium Sports Camp.
      </checkbox-input>
      <form-wizard-next-button />
    </form>
  </form-wizard-step>
</template>

<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Registration } from '@/store/models/orderables/registration/Registration.model';

@Component
export default class RegistrationAddressStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  registration!: Registration;

  error = false;

  validate () {
    const form = this.$refs.form as HTMLFormElement;
    return form.checkValidity();
  }

  onValidationError () {
    this.error = true;
  }

  onBeforeLeave () {
    return this.registration.saveDetails([
      'address',
      'phone',
      'previousEnrollment',
    ]);
  }

  onLeave () {
    this.error = false;
  }
}
</script>
