import { Component, Vue } from 'vue-property-decorator';

import registrationsQuery from '@/graphql/queries/registrations.graphql';

@Component({
  apollo: {
    userRegistrations: {
      query: registrationsQuery,
      variables: {
        includePending: false,
      },
      update: data => data.user!.registrations!,
      fetchPolicy: 'network-only',
      loadingKey: 'userRegistrationsLoaded',
    },
  },
})
export class LoadUserRegistrationsMixin extends Vue {
  userRegistrations: GQL.Registration[] = [];
  userRegistrationsLoaded = false;

  refetchRegistrations () {
    this.$apollo.queries.userRegistrations.refetch();
  }

  get userRegistrationsExists () {
    return this.userRegistrations.length > 0;
  }
}
