<template>
  <div v-if="show">
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class IE11Render extends Vue {
  show = false;

  created () {
    // @ts-ignore
    this.show = !!window.MSInputMethodContext && !!document.documentMode;
  }
}
</script>
