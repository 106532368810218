<template>
  <transition :name="transition">
    <div v-if="show">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DelayedRender extends Vue {
  @Prop({ type: Number, required: true })
  delay!: number;

  @Prop({ type: String, default: null })
  transition!: string;

  show = false;

  mounted () {
    setTimeout(() => { this.show = true }, this.delay);
  }
}
</script>
