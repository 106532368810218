import { apolloClient } from '@/graphql/apollo';
import supplementUpdateRegistrationMutation from '@/graphql/mutations/supplementUpdateRegistration.graphql';

import { CartOrderable } from '../../CartOrderable.model';

import { Timetable } from './Timetable.model';
import { RegistrationDetails } from './RegistrationDetails.model';

export type RegistrationDetailsAttribute = keyof RegistrationDetails & keyof GQL.UpdateRegistrationInput;

export class Registration extends CartOrderable<Registration> implements GQL.CartRegistrationInput {
  id!: string;
  acceptsPrivacyPolicy = true;
  cityId = window.activeCityId;
  firstName = '';
  lastName = '';
  birthday = '';
  // @ts-ignore
  gender: GQL.Gender = null;
  // @ts-ignore
  timetable: Timetable = null;
  details: RegistrationDetails = new RegistrationDetails();

  isReserve = false;
  isSibling = false;
  reservePaymentPending = false;
  isComplete = false;

  static loadFromGQL (registration: GQL.RegistrationInfoFragment) {
    const p = new Registration();
    p.id = registration.id;
    p.cityId = registration.city.id;
    p.firstName = registration.firstName;
    p.lastName = registration.lastName;
    p.birthday = registration.birthday;
    p.gender = registration.gender;
    p.isSibling = registration.isSibling;
    p.isReserve = registration.isReserve;
    p.reservePaymentPending = registration.reservePaymentPending;
    p.isComplete = registration.isComplete;
    p.timetable = new Timetable(registration.timetable, registration.city);

    // Supplement step 1
    p.details.address = {
      street: registration.address!.street!,
      zipcode: registration.address!.zipcode!,
      city: registration.address!.city!,
      country: registration.address!.country!,
    };
    p.details.phone = registration.phone || '';
    p.details.previousEnrollment = !!registration.previousEnrollment;

    // Supplement step 2
    p.details.sportPosition = registration.sportPosition || '';
    p.details.tshirtId = registration.tshirt?.id || null;
    p.details.club = registration.club!;

    // Supplement step 3
    p.details.relativeName = registration.relativeName! || '';
    p.details.relativeRelation = registration.relativeRelation! || '';
    p.details.relativePhone = registration.relativePhone! || '';
    p.details.relative2Name = registration.relative2Name! || '';
    p.details.relative2Relation = registration.relative2Relation! || '';
    p.details.relative2Phone = registration.relative2Phone! || '';
    p.details.friends = registration.friends! || '';
    p.details.allowPhoto = registration.allowPhoto!;

    // Supplement step 4
    p.details.foodAllergyListRaw = registration.foodAllergyList! || [];
    p.details.foodAllergyDescription = registration.foodAllergyDescription! || '';
    p.details.disorderDescription = registration.disorderDescription! || '';
    p.details.medicationDescription = registration.medicationDescription! || '';
    p.details.swimAllowed = registration.swimAllowed!;

    // Supplement step 5
    p.details.otherInfo = registration.otherInfo! || '';

    return p;
  }

  get isLoaded () {
    return !!this.id;
  }

  /**
   * Registration fullname
   */
  get fullname () {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * True if in edit mode
   */
  get haveActiveReservation () {
    return !!this.id;
  }

  /**
   * Birth date correctly formatted
   */
  get formattedBirthday () {
    return this.birthday.replace(/\s*-\s*/g, ' - ');
  }

  /**
   * Birth date correctly formatted
   */
  set formattedBirthday (birthday) {
    this.birthday = birthday.replace(/\s+-\s+/g, '-');
  }

  /**
   * Get timetable id
   */
  get timetableId () {
    return this.timetable.timetable.id;
  }

  /**
   * Orderable hook
   */
  beforeDelete () {
    return confirm(`
      Du är på väg att ta bort deltagaren ${this.firstName},
      är du säker?\nDin reservation kommer att tas bort och går inte att ångra.
    `);
  }

  /**
   * Set registration id for future updates
   */
  setId (id: string) {
    this.id = id;
  }

  /**
   * Create graphql create registration input from object
   */
  toGQLCreateRegistrationArgs (): GQL.MutationCartCreateRegistrationArgs['registration'] {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      birthday: this.birthday,
      gender: this.gender,
      timetableId: this.timetableId,
      isSibling: this.isSibling,
    };
  }

  /**
   * Save registration details on backend
   * @param fields List of fields to update
   */
  saveDetails (fields: RegistrationDetailsAttribute[]) {
    const registration: GQL.UpdateRegistrationInput = fields.reduce((data, field) => {
      data[field] = this.details[field];
      return data;
    }, {
      // Pre-populate id
      id: this.id,
    });

    return apolloClient.mutate<GQL.SupplementUpdateRegistrationMutation>({
      mutation: supplementUpdateRegistrationMutation,
      variables: { registration },
    });
  }
}
