<template>
  <div class="spinner" />
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Loading extends Vue {}
</script>
<style lang="scss">
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #40C272;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
</style>
