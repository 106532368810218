import { CartItem } from './CartItem.model';

export class CartOrderable<T> {
  /**
   * Parent cart item
   */
  public cartItem!: CartItem<T>;

  public $rawData!: string;
  [key: string]: any;

  public static createGeneralOrderable (obj: any) {
    const orderable = new CartOrderable<any>();
    orderable.$rawData = obj;

    return orderable;
  }

  /**
   * Orderable hook
   */
  beforeDelete () {
    return confirm('Du är på väg att ta bort denna artikel från varukorgen. Är du säker?');
  }
}
