import { Controller } from '@hotwired/stimulus';
import { debounce } from 'debounce';
// Connects to data-controller="auto-submit-form"
export default class extends Controller {
  initialize () {
    this.submit = debounce(this.submit, 200);
  }

  submit (event) {
    event.target.form.requestSubmit();
  }
}
