import { Vue, Component, Prop } from 'vue-property-decorator';

import { VariantMixin, StateMixin } from '@/mixins/ClassHelperMixins';

export interface CustomSelectOption {
  text: string;
  value: any;
  disabled?: boolean;
}

export interface CustomSelectOptionGroup {
  text: string
  options: (CustomSelectOption | string)[]
}

@Component({
  inheritAttrs: false,
  mixins: [
    VariantMixin('custom-box-input'),
    StateMixin,
  ],
})
export default class CustomSelect extends Vue {
  /**
   * Input model
   */
  @Prop(String)
  value!: string;

  /**
   * Input label
   */
  @Prop(String)
  label!: string;

  /**
   * Select options
   */
  @Prop({ type: Array, default: () => [] })
  options!: CustomSelectOption[] | string[];

  /**
   * Select options
   */
  @Prop({ type: Array, default: () => [] })
  groupedOptions!: CustomSelectOptionGroup[];

  /**
   * Placeholder option text
   */
  @Prop({ type: String, default: 'Välj ett alternativ' })
  placeholder!: string;

  // Get all listeners except input
  get listeners () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { input, ...other } = this.$listeners;
    return other;
  }

  get selectOptions () {
    return this.options.map(option => this.optionToObject(option));
  }

  get groupedSelectOptions () {
    return this.groupedOptions.map(group => ({
      text: group.text,
      options: group.options.map(option => this.optionToObject(option)),
    }));
  }

  onChange (e) {
    this.$emit('input', e.target.value);
    this.$emit('change', e.target.value);
    this.$emit('update:state', null);
  }

  private optionToObject (option: string | CustomSelectOption) {
    if (typeof option === 'string')
      option = {
        text: option,
        value: option,
      };

    return option;
  }
}
