import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import sportsQuery from '@/graphql/queries/sports.graphql';
import { Timetable } from '@/store/models/orderables/registration/Timetable.model';
import { Site } from '@/store/modules/Site';
import { Registration } from '@/store/models/orderables/registration/Registration.model';

// Use jquery
const $ = window.$;

interface CampTypeOption {
  value: string
  name: string
  description: string
}
@Component
export default class AvailableTimetablesList extends Vue {
  Timetable = Timetable;

  @Prop()
  value!: Timetable;

  selectedItem: Timetable | null = null;

  @Prop({ type: Object, required: true })
  registration!: Registration;

  campTypes: CampTypeOption[] = [];
  activeCampTypeId: string | null = null;

  weeks: Partial<GQL.Week>[] = [];
  sports: GQL.Sport[] = [];

  loading = true;

  created () {
    this.onValueUpdated();

    // Fetch sports/timetable
    const { registration } = this;
    this.$apollo.query<GQL.SportsQuery>({
      query: sportsQuery,
      variables: {
        cityId: registration.cityId,
        birthday: registration.birthday,
        gender: registration.gender,
        promoCode: registration.cartItem.promoCode,
        registrationId: registration.id,
      },
    }).then(({ data }) => {
      this.campTypes = data.city!.campTypes.map<CampTypeOption>(ct => ({
        value: ct.id,
        name: ct.title,
        description: ct.description,
        registrationFee: ct.registrationFee,
      }));
      this.activeCampTypeId = this.campTypes[0]?.value || null;

      // Set sports list
      this.sports = data.city!.sports! as GQL.Sport[];

      // Clear value if provided timetable is invalid
      if (this.selectedItem && !this.sports!.some(s => s!.timetablesForBirthday!.some(t => t.id === this.selectedItem!.timetable.id)))
        this.$emit('input', null);

      this.loading = false;
      this.initTooltips();
      this.$emit('ready');
    });
  }

  get currentCity () {
    return getModule(Site).cityById(this.registration.cityId);
  }

  get activeCampType () {
    return this.campTypes.find(ct => ct.value === this.activeCampTypeId);
  }

  get activeSports () {
    const sports = this.sports;
    return sports
      .filter(s => s.timetablesForBirthday!.length > 0 && s.timetablesForBirthday.some(t => t.availabilityStatus !== 'NOT_AVAILABLE'))
      .reduce((list, sport) => {
        const activeTimetables = sport.timetablesForBirthday.filter(t => t.campType.id === this.activeCampTypeId);

        if (activeTimetables.length > 0)
          list.push({
            ...sport,
            timetablesForBirthday: activeTimetables,
          });

        return list;
      }, [] as typeof sports);
  }

  get activeWeeks () {
    return this.activeSports
      .reduce((weeks, sport) => {
        const sportWeeks = sport.timetablesForBirthday
          .map(timetable => timetable.week)
          .filter(week => !weeks.some(w => w.id === week.id));

        return weeks.concat(sportWeeks);
      }, [] as GQL.Week[]);
  }

  @Watch('value')
  onValueUpdated () {
    this.selectedItem = this.value;
  }

  async initTooltips () {
    await this.$nextTick();
    $('[data-toggle=tooltip]', this.$el).tooltip();
  }
}
