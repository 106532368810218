import { Vue, Component, Provide, Prop, Watch, ProvideReactive } from 'vue-property-decorator';

import FormWizardStep from '../FormWizardStep/FormWizardStep';

@Component({
  inject: [],
})
export default class FormWizard extends Vue {
  /**
   * Component model
   */
  @Prop(Number)
  value!: number;

  /**
   * If enabled, visited steps will be ignored
   */
  @Prop(Boolean)
  @ProvideReactive()
  editMode!: boolean;

  /**
   * Final validation
   */
  @Prop(Function)
  beforeCompleted!: () => Promise<any>;

  /**
   * Current active wizard step
   */
  @ProvideReactive()
  wizardActiveStep = 0;

  /**
   * Number of steps which have been visited
   */
  @ProvideReactive()
  wizardVisitedSteps = 0;

  /**
   * List of wizard step components
   */
  stepComponents: FormWizardStep[] = [];

  /**
   * Prevent changes on active collapse animation
   */
  locked = false;

  /**
   * If wizard is completed
   */
  isCompleted = false;

  /**
   * List of validation errors
   */
  validationErrors = [];

  mounted () {
    if (this.editMode)
      this.wizardVisitedSteps = this.stepComponents.length;

    // Get initial step
    this.onValueUpdated(this.value)
      // Emit event to parent when done
      .then(() => this.$emit('loaded'));
  }

  /**
   * Unique wizard id
   */
  @Provide()
  private get wizardId () {
    // @ts-ignore
    return `form-wizard-${this._uid}`;
  }

  /**
   * Provide step registration method
   * @param component Wizard step component
   */
  @Provide()
  public registerWizardStep (component: FormWizardStep) {
    this.stepComponents.push(component);
    // Return added step number
    return this.stepComponents.length;
  }

  /**
   * Watch model changes from parent
   * @param value New value
   * @param previousValue Previous value
   */
  @Watch('value')
  private onValueUpdated (value: number, previousValue?: number) {
    // Don't fast forward if edit mode
    if (this.editMode)
      return this.goToStep(value, false, previousValue);

    // Use fast-forward mode if new value is larger than the previous value
    const fastForward = !previousValue || value >= previousValue;
    return this.goToStep(value, fastForward, previousValue);
  }

  /**
   * Visit wizard step
   * @param requestedStep Step to visit
   * @param fastForward Skip previously visited and validating steps
   * @param previousStep Previous step (optional)
   */
  @Provide()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async goToStep (requestedStep: number, fastForward = false, previousStep?: number) {
    // If requested step is already active
    let nextStep = requestedStep;
    if (!nextStep || nextStep === this.wizardActiveStep)
      return this.wizardActiveStep;

    // Prevent any changes if locked state
    if (this.locked) {
      this.$emit('input', this.wizardActiveStep);
      return this.wizardActiveStep;
    }

    // Prevent steps with audit mode enabled to be skipped
    for (let i = this.wizardActiveStep + 1; i < nextStep; i++)
      if (this.getStepComponent(i).audit && process.env.NODE_ENV === 'development') {
        console.warn(`FormWizard prevented to go from step ${this.wizardActiveStep} to ${requestedStep} as step ${i} requires audit`);
        nextStep = i;
        break;
      }

    // Validate subsequent steps until failing
    const numValidatingSteps = await this.checkNumValidatingSteps();
    const totalNumSteps = this.stepComponents.length;

    // Prevent fast-forward submission of form
    if (requestedStep > totalNumSteps && requestedStep !== this.wizardActiveStep + 1)
      requestedStep = this.wizardVisitedSteps;

    // Check if all steps are completed
    if (requestedStep > totalNumSteps && numValidatingSteps === totalNumSteps) {
      const activeStepComponent = this.getStepComponent(this.wizardActiveStep);
      const canLeave = await Promise.resolve(activeStepComponent.beforeLeave()).then(d => !!d).catch(() => false);
      if (!canLeave)
        return this.wizardActiveStep;

      if (!this.isCompleted) {
        let completionValidates = true;
        // If completed callback is provided
        if (this.beforeCompleted)
          completionValidates = await this.beforeCompleted().then(v => v !== false).catch(() => false);

        if (!completionValidates)
          return this.wizardActiveStep;

        activeStepComponent.$emit('leave');
        this.$emit('completed');
        this.isCompleted = true;
      } else {
        activeStepComponent.$emit('leave');
      }

      return this.wizardActiveStep;
    }

    // Skip previously visited and validating steps if fast-forward mode
    if (fastForward)
      while (nextStep < numValidatingSteps)
        if (nextStep < this.wizardVisitedSteps || this.getStepComponent(nextStep).forwards)
          nextStep++;
        else
          break;

    // Emit validation error if not validating requested step
    if (nextStep - 1 > numValidatingSteps) {
      nextStep = numValidatingSteps + 1;
      this.getStepComponent(nextStep).$emit('error', this.validationErrors);
    }

    // If calculated next step is already active
    if (nextStep === this.wizardActiveStep)
      return this.wizardActiveStep;

    // Emit events
    if (this.wizardActiveStep) {
      const activeStepComponent = this.getStepComponent(this.wizardActiveStep);
      const canLeave = await Promise.resolve(activeStepComponent.beforeLeave()).then(d => !!d).catch(() => false);
      if (!canLeave)
        return this.wizardActiveStep;

      this.getStepComponent(this.wizardActiveStep).$emit('leave');
    }
    this.getStepComponent(nextStep).$emit('enter');

    // Set active step
    this.wizardActiveStep = nextStep;
    this.$emit('input', nextStep);

    this.locked = true;
    this.getStepComponent(nextStep)
      .expandStep()
      .then(() => { this.locked = false });

    // Update number of visited steps
    if (nextStep > this.wizardVisitedSteps)
      this.wizardVisitedSteps = nextStep;

    return this.wizardActiveStep;
  }

  /**
   * Go to next step
   */
  @Provide()
  public async nextStep () {
    if (this.editMode)
      return this.goToStep(this.wizardActiveStep + 1);

    if (this.wizardActiveStep < this.wizardVisitedSteps)
      return this.goToStep(this.wizardVisitedSteps);

    return await this.goToStep(this.wizardVisitedSteps + 1);
  }

  /**
   * Go to previous step
   */
  @Provide()
  public previousStep () {
    this.goToStep(this.value - 1);
  }

  /**
   * Get wizard step component instance
   * @param step Wizard step number
   */
  private getStepComponent (step: number) {
    return this.stepComponents[step - 1];
  }

  /**
   * Validate all steps in chronological order until failing
   */
  private async checkNumValidatingSteps () {
    let i = 0;

    while (i < this.stepComponents.length) {
      const validates = await Promise.resolve(this.getStepComponent(i + 1).validate())
        .catch((errors) => {
          this.validationErrors = errors;
          return false;
        });

      if (!validates)
        break;

      i++;
    }

    // Last validating step
    return i;
  }
}
