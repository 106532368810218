<template>
  <form-wizard-step
    title="Information om vistelsen"
    :validate="validate"
    :before-leave="onBeforeLeave"
    @error="onValidationError"
    @leave="onLeave"
  >
    <form ref="form" :class="{ 'was-validated': error }">
      <div class="row">
        <div class="col-md-6 pt-2">
          <h5>Kontaktperson 1</h5>
          <custom-input
            id="contactPerson"
            v-model="registration.details.relativeName"
            label="För- och efternamn"
            autocomplete="off"
            required
          />
          <custom-input
            id="contactPersonRelation"
            v-model="registration.details.relativeRelation"
            label="Personens relation till deltagaren"
            help-label="Exempelvis mamma, målsman eller farbror."
            autocomplete="off"
            required
          />
          <custom-input
            id="contactPersonPhone"
            v-model="registration.details.relativePhone"
            label="Telefonnummer"
            autocomplete="off"
            required
          />
        </div>
        <div class="col-md-6 pt-2">
          <h5>Kontaktperson 2 (frivillig)</h5>
          <custom-input
            id="contactPerson"
            v-model="registration.details.relative2Name"
            label="För- och efternamn"
            autocomplete="off"
          />
          <custom-input
            id="contactPersonRelation"
            v-model="registration.details.relative2Relation"
            label="Personens relation till deltagaren"
            help-label="Exempelvis mamma, målsman eller farbror."
            autocomplete="off"
          />
          <custom-input
            id="contactPersonPhone"
            v-model="registration.details.relative2Phone"
            label="Telefonnummer"
            autocomplete="off"
          />
        </div>
      </div>
      <hr>
      <custom-input
        id="registrationFriends"
        v-model="registration.details.friends"
        label="Kompisar som deltagaren vill träna med inom samma idrott"
        help-label="Max tre (3) stycken. Ange både för- och efternamn. Separera namn med kommatecken."
        autocomplete="off"
        optional
      />
      <div class="pt-2">
        <h6 class="font-size-sm">
          Godkännande av fotografering
        </h6>
        <p class="mb-3 font-size-xs">
          Jag godkänner att mitt barn fotograferas under Stadium Sports Camp.
          <a
            class="font-weight-bold"
            target="_blank"
            href="/villkor-fotografering/fotovillkor"
          >Läs fullständiga villkor här</a>.
        </p>
        <radio-group
          v-model="registration.details.allowPhoto"
          variant="primary"
          lg
          required
          :options="allowPhotoOptions"
        />
      </div>
      <form-wizard-next-button />
    </form>
  </form-wizard-step>
</template>

<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Registration } from '@/store/models/orderables/registration/Registration.model';
import { createYesNoOptions } from '@/store/models/orderables/registration/RegistrationDetails.model';

@Component
export default class VisitDetailsStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  registration!: Registration;

  error = false;

  allowPhotoOptions = createYesNoOptions('Ja, jag godkänner', 'Nej, jag godkänner inte');

  validate () {
    const form = this.$refs.form as HTMLFormElement;
    return form.checkValidity();
  }

  onValidationError () {
    this.error = true;
  }

  onBeforeLeave () {
    return this.registration.saveDetails([
      'relativeName',
      'relativeRelation',
      'relativePhone',
      'relative2Name',
      'relative2Relation',
      'relative2Phone',
      'friends',
      'allowPhoto',
    ]);
  }

  onLeave () {
    this.error = false;
  }
}
</script>
