import { Controller } from '@hotwired/stimulus';

const openClass = 'du-modal-open';
export default class extends Controller {
  static targets = ['frame', 'title', 'standalone'];

  /** @type {MutationObserver} */
  observer;

  /** @type {string} */
  lastSrc;

  visit () {
    location.href = this.frameTarget.getAttribute('src');
  }

  onClickOutside (event) {
    if (event.target === this.element)
      this.hideModal();
  }

  connect () {
    this.element.addEventListener('click', this.onClickOutside.bind(this));

    // Watch for src changes
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const frame = mutation.target;

        if (mutation.attributeName !== 'busy' || frame.getAttribute('busy') !== null)
          return;

        if (frame.getAttribute('src') !== this.lastSrc)
          this.showModal();

        if (frame.getAttribute('src') === '')
          this.hideModal();
      });
    });

    this.observer.observe(this.frameTarget, { attributes: true });
  }

  disconnect () {
    this.element.removeEventListener('click', this.onClickOutside.bind(this));
    this.observer.disconnect();
  }

  showModal () {
    this.lastSrc = this.frameTarget.getAttribute('src');

    setTimeout(() => {
      /** @type {HTMLElement} */
      const element = this.element;
      element.classList.add(openClass);

      // eslint-disable-next-line max-len
      const standaloneMode = this.frameTarget.querySelector('[data-modal-standalone]')?.dataset.modalStandalone;
      const modalTitle = this.frameTarget.querySelector('[data-modal-title]')?.dataset.modalTitle || '';

      this.titleTarget.innerHTML = modalTitle;
      this.standaloneTarget.style.display = standaloneMode === 'true' ? 'block' : 'none';
    }, 50);
  }

  hideModal () {
    /** @type {HTMLElement} */
    const element = this.element;
    element.classList.remove(openClass);

    this.lastSrc = '';

    // Reset modal state
    this.titleTarget.innerHTML = '';
    this.standaloneTarget.style.display = 'none';
  }
}
