/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import * as path from 'path';

// eslint-disable-next-line import/order
import $ from 'jquery';
import 'slick-slider';
import 'unfetch/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';

import Rails from '@rails/ujs';
import Vue from 'vue';
import VueRouter from 'vue-router';
import TurbolinksAdapter from 'vue-turbolinks';
import VMask from 'v-mask';
import VueApollo from 'vue-apollo';
import { Component } from 'vue-property-decorator';
import * as moment from 'moment';
import App from '@/App.vue';
import { routes } from '@/routes';
import { store } from '@/store';
import { apolloProvider } from '@/graphql/apollo';
import 'bootstrap';
import 'controllers';
import '../vendor/countdown_widget';
// =========================================================================
// =========================================================================
// =========================================================================
// =========================================================================
// =========================================================================

import 'vanilla-cookieconsent';

Rails.start();

// Set swedish locale
// eslint-disable-next-line import/namespace
moment.locale('sv');
require('dotenv').config({ path: path.resolve(__dirname, '../../../.env') });
Vue.use(VueRouter);
Vue.use(TurbolinksAdapter);
Vue.use(VMask);
Vue.use(VueApollo);

// Define apollo getter from class component
Component.registerHooks(['apollo']);

// Auto import and register components globally
const reqGlobalComponent = require.context('../vue/components', true, /\.vue$/);
reqGlobalComponent.keys().forEach((file) => {
  const component = reqGlobalComponent(file).default;
  // File must export a component
  if (typeof component !== 'function')
    return;

  Vue.component(file.replace(/.+\/([^/]+)\.vue$/, '$1'), component);
});

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    const anchor = document.getElementById('vue-content') as HTMLElement;
    return { x: 0, y: anchor.offsetTop };
  },
});

export const vueSelector = '[data-behavior=vue]';
function addInstance () {
  if (document.querySelector(vueSelector) !== null)
    // eslint-disable-next-line no-new
    new Vue({
      el: vueSelector,
      router,
      store,
      components: { App },
      render: (h): any => h(App),
      apolloProvider,
    });
}

document.addEventListener('turbo:load', addInstance);
addInstance();

window.mobilecheck = function () {
  let check = false;
  (function (a) {
    // eslint-disable-next-line max-len
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

$(function () {
  if (mobilecheck) {}
  // Change city work by collection select
  $('#change-city-form select').on('change', function (e) {
    window.location.href = '/' + $(this).val() + '/jobba-hos-oss';
  });

  const pageIndex = $('.page.active').data('subpage');
  if (pageIndex)
    $('.page[data-subpage=' + pageIndex + ']').show();
});

document.addEventListener('turbo:load', function () {
  // --------------
  // Cookie consent
  //
  /*
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#00A3E4',
        text: '#2A2826',
      },
      button: {
        background: '#2A2826',
        border: '#2A2826',
        text: '#ffffff',

      },
    },
    showLink: true,
    content: {
      message: 'Vi använder cookies för att ge dig en bra upplevelse på vår webbplats. Genom att surfa vidare godkänner du att cookies används.',
      dismiss: 'Ok, jag förstår!',
      link: 'Läs mer om vår integritetspolicy.',
      href: gdpr_policy_url,
      target: '_blank',
    },
  });
  */

  //
  // Main navigation
  //

  // if you change this breakpoint in the sass files, don't forget to update this value as well
  const MqL = 992;

  // reset states on load
  $('html').removeClass('disable-scroll');
  closeNav();

  // move nav element position according to window width
  moveNavigation();
  $(window).on('resize', function () {
    (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
  });

  // mobile - open lateral menu clicking on the menu icon
  //
  $('.mobile-nav-toggle').on('click', function (event) {
    event.preventDefault();

    $(this).attr('aria-expanded', function (index, attr) {
      return attr == 'true' ? 'false' : 'true';
    });

    if ($('body').hasClass('mobile-nav-is-open')) {
      closeNav();

      // Enable scroll
      var scrollTop = parseInt($('html').css('top'));
      $('html').removeClass('disable-scroll');
      $('html,body').scrollTop(-scrollTop);
    } else {
      $('.main-navigation').addClass('mobile-nav-is-open');
      $('body').addClass('mobile-nav-is-open').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
        $('body').addClass('overflow-hidden');
      });
      // Disable scroll
      if ($(document).height() > $(window).height()) {
        var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
        $('html').addClass('disable-scroll').css('top', -scrollTop);
      }
    }
  });

  // close lateral menu on mobile
  //
  $('.main-navigation-overlay').on('click', function () {
    closeNav();
  });

  // prevent default clicking on direct children of .primary-nav
  //
  $('.primary-nav').children('.has-children').children('a').on('click', function (event) {
    event.preventDefault();
  });
  // open submenu
  //
  $('.has-children').children('a').on('click', function (event) {
    if (!checkWindowWidth()) event.preventDefault();
    $('.top-bar').addClass('submenu-is-open');
    const selected = $(this);

    selected.attr('aria-expanded', function (index, attr) {
      return attr == 'true' ? 'false' : 'true';
    });

    if (selected.next('.nav-level').hasClass('is-hidden')) {
      // desktop version only
      selected.addClass('selected').next('.nav-level').removeClass('is-hidden').end().parent('.has-children').parent('.nav-level').addClass('moves-out');
      selected.parent('.has-children').siblings('.has-children').children('.nav-level').addClass('is-hidden').end().children('a').removeClass('selected').attr('aria-expanded', 'false');
      $('.main-navigation-overlay').addClass('is-visible');
    } else {
      selected.removeClass('selected').next('.nav-level').addClass('is-hidden').end().parent('.has-children').parent('.nav-level').removeClass('moves-out').attr('aria-expanded', 'false');
      $('.main-navigation-overlay').removeClass('is-visible');
      $('.top-bar').removeClass('submenu-is-open');
    }
  });

  // submenu items - go back link
  $('.go-back').on('click', function (e) {
    e.preventDefault();
    $('.has-children a').attr('aria-expanded', 'false');
    $(this).closest('.nav-level').addClass('is-hidden').closest('.has-children').closest('.nav-level').removeClass('moves-out');
  });

  function closeNav () {
    $('.main-navigation').removeClass('mobile-nav-is-open');
    $('.top-bar').removeClass('submenu-is-open');
    $('.main-navigation-overlay').removeClass('is-visible');
    $('.has-children .nav-level').addClass('is-hidden');
    $('.has-children a').removeClass('selected').attr('aria-expanded', 'false');
    $('.moves-out').removeClass('moves-out');
    $('body').removeClass('mobile-nav-is-open').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
      $('body').removeClass('overflow-hidden');
    });
  }

  function checkWindowWidth () {
    // check window width (scrollbar included)
    let e = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    if (e[a + 'Width'] >= MqL)
      return true;
    else
      return false;
  }

  //
  // Move navigation into .top-bar on desktop
  //
  function moveNavigation () {
    const navigation = $('.main-navigation');
    const desktop = checkWindowWidth();
    if (desktop) {
      navigation.detach();
      navigation.insertAfter('.menu-anchor');
    } else {
      navigation.detach();
      navigation.insertBefore('.page-content');
    }
  }

  //
  // Plyr
  //
  const player = new Plyr('#player');

  $('#videoModal').on('hide.bs.modal', function () {
    player.pause();
  });
  $('#videoModal').on('show.bs.modal', function () {
    player.play();
  });

  // --------------
  // Slick slider
  //
  $('.camp-friends-slick-slider').slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: '<i class="fas fa-arrow-left"></i>',
    nextArrow: '<i class="fas fa-arrow-right"></i>',
  });

  // --------------
  // Cells/menu/news_posts: Set will_paginate prev/next texts
  //
  $('.previous_page').text('JS-icon Visa tidigare nyheter');
  $('.next_page').text('Visa äldre nyheter JS-icon');

  //
  // Enable Bootstrap tooltips
  //
  $('[data-toggle="tooltip"]').tooltip();
});

document.addEventListener('turbo:load', function () {
  $('#worker-sport-select').change(function (e) {
    const sportId = $(e.currentTarget).val();
    $.getJSON('/norrkoping/api/available_weeks_for_sport/' + sportId, function (data) {
      $('#week1').prop('disabled', true);
      $('#week2').prop('disabled', true);
      $('#week3').prop('disabled', true);
      $('#week4').prop('disabled', true);
      $('#week1').prop('checked', false);
      $('#week2').prop('checked', false);
      $('#week3').prop('checked', false);
      $('#week4').prop('checked', false);
      data.forEach(function (week) {
        $('#week' + week.number).prop('disabled', false);
      });
    });
  });
});
