import { Vue, Component, Prop } from 'vue-property-decorator';

import { VariantMixin, SizingMixin, StateMixin } from '@/mixins/ClassHelperMixins';

interface Options {
  text: string;
  value: string;
  disabled: boolean;
}

@Component({
  inheritAttrs: false,
  mixins: [
    VariantMixin('custom-box-radio'),
    SizingMixin('custom-box-radio'),
    StateMixin,
  ],
})
export default class RadioGroup extends Vue {
  /**
   * Radio model
   */
  @Prop()
  value!: any;

  /**
   * Radio group options
   */
  @Prop({ default: [] })
  options!: Options[];

  /**
   * Radio group label
   */
  @Prop(String)
  label!: string;

  /**
   * Bottom help text
   */
  @Prop(String)
  helpLabel!: string;

  /**
   * Message to display on validation errors
   */
  @Prop(String)
  invalidFeedback!: string;

  /**
   * Unique component id
   */
  get cid () {
    // @ts-ignore
    return this.$attrs.name || `radio-group-${this._uid}`;
  }

  /**
   * Get all listeners except input
   */
  get listeners () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { input, ...other } = this.$listeners;
    return other;
  }
}
