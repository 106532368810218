import { getModule } from 'vuex-module-decorators';

import { CartDiscount } from './CartDiscount.model';
import { CartOrderable } from './CartOrderable.model';

import { apolloClient } from '@/graphql/apollo';
import { Cart } from '@/store/modules/Cart';
import { fetchCart } from '@/mixins/LoadGQLCartMixin';
import cartDeleteArticleMutation from '@/graphql/mutations/cartDeleteArticle.graphql';

export class CartItem<T = any> {
  /**
   * Unique article id
   */
  id!: string;

  /**
   * Article title
   */
  title!: string;

  /**
   * Article price
   */
  price = 0;

  /**
   * Article promo code
   */
  promoCode = '';

  /**
   * Registered discount modifiers
   */
  discounts: CartDiscount[] = [];

  /**
   * Article price
   */
  validUntil!: string;

  /**
   * Item related to article
   */
  orderable!: CartOrderable<T>;

  constructor (cartItem: Partial<GQL.CartItem>) {
    this.id = cartItem.id!;
    this.title = cartItem.articleName!;
    this.price = cartItem.price!;
    this.validUntil = cartItem.validUntil!;
    this.promoCode = cartItem.promoCode!;
  }

  /**
   * Create an empty cart item with optional orderable
   */
  static createEmpty<T> (orderable?: CartOrderable<T>) {
    const cartItem = new CartItem<T>({
      id: '',
      articleName: '',
      price: 0,
      validUntil: '',
    });

    if (orderable)
      cartItem.orderable = orderable;

    return cartItem;
  }

  /**
   * Calculate price with discounts
   */
  get priceWithDiscounts () {
    return this.discounts.reduce((price, discount) => price + discount.effect, this.price);
  }

  /**
   * Register discounts modifiers
   * @param discounts Discount type
   */
  setDiscounts (discounts: CartDiscount[]) {
    this.discounts = discounts;
    return this;
  }

  /**
   * Set related article item
   * @param item Item
   */
  setOrderable (orderable: CartOrderable<T>) {
    this.orderable = orderable;
    orderable.cartItem = this;

    return this;
  }

  /**
   * Save article in store
   */
  save () {
    getModule(Cart).addOrUpdateArticle(this);
  }

  /**
   * Remove article from cart
   */
  async delete () {
    const mutation = await apolloClient.mutate<GQL.CartDeleteArticleMutation>({
      mutation: cartDeleteArticleMutation,
      variables: {
        id: this.id,
      },
    });
    const deleted = mutation.data!.cartDeleteArticle;

    if (deleted)
      getModule(Cart).deleteArticle(this);

    // Update cart
    fetchCart();

    return deleted;
  }
}
