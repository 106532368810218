<template>
  <custom-select
    v-show="options.length > 1"
    :value="value"
    :options="options"
    required
    label="Stad"
    name="city"
    placeholder="Välj en stad"
    @input="v => $emit('input', v)"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import allCitiesQuery from '@/graphql/queries/allCities.graphql';
import { Site } from '@/store/modules/Site';

@Component({
  apollo: {
    allCities: {
      query: allCitiesQuery,
      result ({ data }: { data: GQL.AllCitiesQuery }) {
        getModule(Site).setCities(data.allCities!);
      },
    },
  },
})
export default class CitiesSelect extends Vue {
  @Prop()
  value: any;

  mounted () {
    if (this.options.length === 1)
      this.$emit('input', this.options[0].value);
  }

  get options () {
    return getModule(Site).cities.map(city => ({
      value: city.id,
      text: city.name,
    }));
  }
}
</script>
