<template>
  <div class="row justify-content-center">
    <div class="col-lg-9 col-xl-8 pt-4">
      <button
        class="btn btn-lg btn-block btn-primary"
        data-test-id="wizard-next-step"
        v-bind="$attrs"
        @click.prevent="onClick"
        v-on="$listeners"
      >
        <slot>Fortsätt</slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class FormWizardNextButton extends Vue {
  /**
   * Inject from FormWizard
   */
  @Inject()
  nextStep!: () => void;

  /**
   * Use button without functionality
   */
  @Prop(Boolean)
  dryRun!: boolean;

  /**
   * Open and autoscroll to next wizard step
   */
  async onClick () {
    if (this.dryRun)
      return;

    const stepNum = await this.nextStep();
    await new Promise(resolve => setTimeout(resolve, 400));

    // Scroll to wizard step
    const div = document.getElementById(`steg-${stepNum}`);
    if (div)
      window.scrollTo({ top: div.offsetTop + 50, behavior: 'smooth' });
  }
}
</script>
