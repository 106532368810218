<template>
  <component
    :is="component"
    :to="to"
    class="text-white d-inline-flex align-items-center py-2 pr-3"
    href="#"
    @click.prevent="onClick"
  >
    <i class="fas mr-3 fa-arrow-left" />
    {{ text }}
  </component>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BackButton extends Vue {
  @Prop()
  to: any;

  @Prop(String)
  text!: string;

  get component () {
    return this.to ? 'router-link' : 'a';
  }

  /**
   * Imitate native back button if no destination was given
   */
  onClick () {
    if (this.to)
      return;

    this.$router.go(-1);
  }
}
</script>
<style scoped>
  .text-white:hover {
    text-decoration: none;
    opacity: 0.85;
  }
</style>
